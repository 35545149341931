.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.v-center {
  align-items: center;
}

.h-center {
  justify-content: center;
}